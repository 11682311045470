const DrawerPinMessageStyle = {
    BODY_STYLE: {
        minHeight: '5rem',
    },
    WRAPPER_STYLE: {
        padding: 0,
    },
    HEIGHT: '50px',
};

export default DrawerPinMessageStyle;
