const MODAL_JOIN_FROM_LINK_STYLE = {
    BODY_MODAL: {
        padding: '0',
    },
    WIDTH: 400,
    STYLE: {
        top: 20,
    },
};

export default MODAL_JOIN_FROM_LINK_STYLE;
